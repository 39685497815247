<template>
  <AppTitle>Artikel</AppTitle>
  <ArticlesCardList
    :articles="articles"
    :is-loading="isLoading"
    :current-page="page"
    :total-pages="totalPages"
    @fetchMore="nextPage"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { StoryblokResult, StoryData } from 'storyblok-js-client';
import { ArticleModel } from '@/models/article.model';
import AppTitle from '@/components/ui/AppTitle.vue';
import { fetchArticles } from '@/composables/api';
import { parseStoryblokResultToStoryData } from '@/composables/storyblokResultParser';
import ArticlesCardList from '@/components/ui/ArticlesCardList.vue';
import usePagination, { FetchFnReturn } from '@/composables/usePagination';

export default defineComponent({
  components: {
    ArticlesCardList,
    AppTitle,
  },
  setup() {
    const fetch = async (page: number): Promise<FetchFnReturn<StoryData<ArticleModel>>> => {
      const result: StoryblokResult = await fetchArticles(page);

      return {
        items: parseStoryblokResultToStoryData(result),
        total: result.total,
        perPage: result.perPage,
      };
    };

    const { next: nextPage, isLoading, items: articles, page, totalPages } = usePagination(fetch);

    return { articles, fetch, isLoading, totalPages, page, nextPage };
  },
});
</script>
